:root {
  --bg-color: #181818;
  --bg-color-rgb: 24, 24, 24; /* 181818 in RGB */
}

body {
  background: var(--bg-color) !important;
}

.App-header {
  font-size: larger;
  margin-top: 9px !important;
  padding-bottom: -5px !important;
  margin-bottom: 0px !important;
  margin-right: 9px !important;
}

.App-menu {
  margin: 0em !important;
  margin-left: -14px !important;
  top: 0;
  position: fixed;
  z-index: 1000;
}

.ui.tabular.menu {
  background-color: var(--bg-color) !important;
}

.customBG .ui.tabular.menu {
  background-color: transparent !important;
}

.customBG.active .ui.tabular.menu {
  background-color: #000 !important; /* Target color */ /* Initial state */
  transition: background-color 0.5s ease-in-out; /* Smooth transition */
}

.customBG.active2 .ui.tabular.menu {
  background-color: #222222a0 !important; /* Target color */ /* Initial state */
  transition: background-color 0.5s ease-in-out; /* Smooth transition */
}

.customBG .ui.table {
  background-color: #1e1e1e !important;
  border: 1px solid black !important;
}

.customBG .ui.striped.table>tbody>tr:nth-child(2n) {
  background-color: #161616 !important;
}

.customBG .ui.celled.table>tbody>tr>td, .customBG .ui.celled.table>tbody>tr>td, .customBG .ui.sortable.table>thead>tr>th{
  border-left: 1px solid black;
}

.customBG .ui.table>thead>tr>th {
  border-bottom: 1px solid black;
}

.customBG .ui.table>tbody>tr>td {
  border-top: 1px solid black;
}

.customBG .ui.input>input {
  border: 1px solid black !important;
}

.customBG .ui.button {
  background: #1a1a1a;
}

.customBG .eventSection {
  background-color: rgb(0, 0, 0, 0.4);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 1vw !important;
  padding-top: 1vw !important;
  margin: 0.5vw !important;
  border: 1px solid rgba(100,100,100,0.1);
}

.customBG .adslot {
  margin-top: 1.5em !important;
}

.customBG .adslot > ins {
  border: 1px solid rgba(100,100,100,0.1) !important; 
}

.customBG .eventSection2 {
  background-color: rgb(0, 0, 0, 0.4);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 1vw !important;
  padding-top: 1vw !important;
  margin: 0.5vw !important;
  border: 1px solid rgba(100,100,100,0.1);
}

.captain-header {
  text-align: center;
  align-items: center;
  font-size: xx-large;
}

.variants, .variantsM, .quipment, .quipmentM {
  display: grid;
  grid-template-columns: 60px 120px;
  padding: 0px !important;
  margin: 3px;
}

.variantsM {
  grid-template-columns: 60px 100px !important;
}

.quipment {
  grid-template-columns: 60px 160px;
}

.quipmentM {
  grid-template-columns: 60px auto;
}

.var-portrait, .q-portrait {
  width: 100%;
  height: 100%;
  max-height: 60px;
  max-width: 60px;
  min-width: 60px;
}

.var-portrait:hover {
  cursor: pointer;
}

.var-details {
  display: table; 
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.var-details-c {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.var-name {
  display: inline-block;
  font-weight: bold;
}

.var-tier {
  display: inline-block;
  font-size: small;
}

.featuredEvent {
  display: grid;
  border: 2px solid #646464;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.feMotd {
  opacity: 0.8;
}

.feOverlay {
  position: absolute;
  display: grid;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(34, 34, 34, 0.6);
  max-width: 40%;
}

.feoTitle {
  font-family: TNG_Title;
  font-size: 3.5vw;
  line-height: 2.5vw;
}

.feoDesc {
  margin: 0.1vw;
  font-size: 1.5vw;
  color:#e7e7e7;
}

.feoTraits {
  display: flexbox;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.nareksoji {
  position: sticky;
  height: 0px;
  z-index: 10;
  top: 5em;
}

.nareksoji-img {
  animation: blink .5s step-end infinite alternate;
  border: 1px #ff0000 solid;
  margin: 0.2em;
}

.nareksoji-txt:hover {
  cursor: pointer;
}

.loadMore {
  text-align: center;
  margin: 1em;
}

@keyframes blink { 
  50% { border-color: #fff; } 
}

.expanded-row {
  background-color: transparent !important;
  margin-left: 10px !important;
  padding-left: 10px;
}

.expanded-cell {
  padding: 10px !important;
  background-color: rgb(34, 34, 34) !important;
}

::selection {
  color: white!important;
  background-color: rgb(116, 116, 116);
}

.expanded-row .expanded-row:hover {
  background-color: transparent !important;
}

.leaderboard-header {
  display: grid;
  text-align: center;
  font-size: x-large;
  margin: 1em;
  margin-bottom: 1.5em;
  font-weight: bold;
}

.event-details {
  font-size: smaller;
  margin: 0.5em;
  font-weight: normal;
  color: gray;
}


.leaderboard-headerrow {
  color: gray;
  font-weight: bold;
  font-size: larger;
}

.leaderboard-entry, .leaderboard-entryb {
  padding: 0.2em !important;
  font-size: medium;
  color: rgb(226, 226, 226);
}

.leaderboard-entryb {
  margin-top: 0.4em;
}

.leaderboard-b {
  height: 0.2em;
}

.leaderboard-top10 {
  padding: 0.2em !important;
  font-size: larger;
  font-weight: bold;
  margin-top: 0.1em !important;
  margin-bottom: 0.1em !important;
  min-height: 1.4em !important;
}


.leaderboard-odd {
  background-color: rgba(61, 61, 61, 0.2);
}

.leaderboard-container {
  display: grid;
  grid-template-columns: auto 1em;
  padding: 10px;
}

.row-header {
  text-align: center !important;
}

.top-pair {
  font-family: monospace, monospace;
}

.nowrap {
  white-space:nowrap;
}

.shuttles-topranks {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: smaller;
}

.searchableTableSearch {
  white-space: nowrap;
}

.legendary {
  color: rgba(253, 210, 106, 1);
}

.superrare {
  color: rgba(170, 45, 235, 1);
}

.rare {
  color: rgba(90, 170, 255, 1);
}

.uncommon {
  color: rgb(77, 163, 58);
}

.common {
  color: rgb(111, 116, 128);
}

.background-layer, .background-layer2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: overlay;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  background-color: rgba(var(--bg-color-rgb), 0.7);
}

.background-layer2 {
  /* top: 52px;
  height: 400px !important;
  background-position: top;
  background-color: rgba(34, 34, 34, 0);
  mask-image: radial-gradient(farthest-side at 50% 0%, black, transparent);
  mask-size: 90% 90%;
  mask-repeat: no-repeat; */
  background-color: rgba(0, 0, 0, 0.8);
}

.background-layer.hidden, .background-layer2.hidden {
  opacity: 0;
  pointer-events: none;
}

.background-layer.visible, .background-layer2.visible {
  opacity: 1;
}

.hideOverflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobileWidth {
  max-width: 100% !important;
}

.highlightTable {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}

.highlightRow {
  border: 2px solid white !important;
}

.highightTopRow {
  border-top: 2px solid white !important;
  border-left: 2px solid white !important;
  border-right: 2px solid white !important;
}

.highlightBottomRow {
  border-bottom: 2px solid white !important;
  border-left: 2px solid white !important;
  border-right: 2px solid white !important;
}

.legendary span:hover, .legendary a:hover, .legendary Link:hover {
  cursor: pointer;
  background-color: rgba(253, 210, 106, 1); 
  color: var(--bg-color);
  border-radius: 0.2em;
}

.superrare span:hover, .superrare a:hover, .superrare Link:hover {
  cursor: pointer;
  background-color: rgba(170, 45, 235, 1);
  color: var(--bg-color);
  border-radius: 0.2em;
}
.rare span:hover, .rare a:hover, .rare Link:hover {
  cursor: pointer;
  background-color: rgba(90, 170, 255, 1);
  color: var(--bg-color);
  border-radius: 0.2em;
}
.uncommon span:hover, .uncommon a:hover, .uncommon Link:hover {
  cursor: pointer;
  background-color: rgb(77, 163, 58);
  color: var(--bg-color);
  border-radius: 0.2em;
}
.common span:hover, .common a:hover, .common Link:hover {
  cursor: pointer;
  background-color: rgb(111, 116, 128);
  color: var(--bg-color);
  border-radius: 0.2em;
}

.eventHeader {
  display: grid;
}

.eventHeader2 {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  vertical-align: middle;
  border-radius: 2vw;
  margin-top: 0.5em;
}

.playerContainer {
  min-height: 100px;
  max-height: 168px;
}

.playerSection {
  color: silver;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.playerHeader {
  padding: 0.2em;
  padding-right: 0 !important;
  margin-top: 0.4em;
  margin-right: 10px;
  text-align: right;
  width: 100%;
}

.playerPeek {
  text-align: right;
  display: grid;
  grid-template-areas:
    "b a"
    "c a";
  grid-template-columns: auto 40px;
  column-gap: 0.2em;
  min-height: 50px;
  width: 100%;
  padding: 0.2em;
  padding-right: 10px !important;
}

.playerAvatar {
  grid-area: a;
  max-width: 40px;
}

.playerName {
  grid-area: b;
  font-weight: bold;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
}

.playerFleet {
  grid-area: c;
  font-size: x-small;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
}

.fleetHeader {
  text-align: left;
  padding: 0.5em !important;
  padding-left: 10px !important;
  padding-top: 0 !important;
}

.fleetName {
  color: white;
  text-align: left;
  padding-left: 10px !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
}

.podsH {
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: 32px auto;
  grid-template-areas: "a b"
    "c c";
  margin-top: 1em;
}

.podsTitle {
  grid-area: a;
  line-height: 100%;
}

.podsSub {
  display: flex;
  align-items: center;
  grid-area: b;
}

.podsDesc {
  grid-area: c;
  margin: 1em;
  margin-left: 0em;
}

.podContainer, .podContainerM {
  display: inline-flex;
  text-align: left;
  margin: 1vh;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0 !important;
  background-color: #262626;
}

.podContainerM {
  display: grid;
}

.podContainerSel {
  background-color: #333333;
}

.pod {
  width: 322px;
  border: 1px solid transparent;
  display: grid;
  height: 450px;
  max-height: 450px;
}

.podHover:hover {
  border: 1px solid black;
  background-color: #252525;
  cursor: pointer;
}

.podDesc {
  display: grid;
  vertical-align: top;
  padding: 1em;
  height: fit-content;
  margin-top: 280px;
}

.podDate {
  color: silver;
  font-size: smaller;
}

.podTitle {
  font-weight: bold;
  font-size: larger;
}

.podSynopsis {
  margin-top: 1em;
  overflow-y: hidden;
  overflow: hidden;
  max-height: 80px;
}

.podVideo, .podVideoM {
  display: inline !important;
  background-color: #262626;
  opacity: 1;
  transition: opacity 1s ease 0s;
}

.podVideo {
  width: 700px !important;
  max-width: 700px !important;
  height: 450px !important;
  max-height: 450px !important;
}

.podVideoT {
  height: 450px !important;
  max-height: 450px !important;
  width: 450px !important;
  max-width: 450px !important;
}

.podVideoM {
  width: 320px !important;
  max-width: 320px !important;
  height: 280px !important;
  max-height: 280px !important;
}

.podVideoMP, .podVideoMPP {
  position: relative !important;
  bottom: 325px !important;
  left: 0 !important;
  width: 320px !important;
  max-width: 320px !important;
  height: 282px !important;
  max-height: 282px !important;
}

.podVideoMPP {
  bottom: 285px !important;
}

.podThumbnail {
  position: absolute;
}


.podPlayIcon {
  position: relative;
  bottom: 60px;
  left: 0;
  margin-left: 1em;
  background-color: red;
  width: 40px;
  line-height: 40px;
  text-align: left;
  -webkit-transition: width .25s ease-in-out;
  -moz-transition: width .25s ease-in-out;
  transition: width .25s ease-in-out;
  overflow: hidden;
  text-wrap: nowrap;
  white-space: nowrap;
}

.podHover:hover .podThumbnail .podPlayIcon {
  width: 130px !important;
}

.podPlay {
  text-align: left;
  width: 40px !important;
}

.podPlayText {
  display: none;
  opacity: 0;
  overflow: hidden;
  white-space:nowrap
}

.podHover:hover .podThumbnail .podPlayIcon .podPlayText {
  min-width: 90px;
  display: inline;
  opacity: 1;
  overflow: hidden;
  white-space:nowrap
}

@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
.fade-in {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards; 
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:0.3s;
    -moz-animation-duration:0.3s;
    animation-duration:0.3s;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.eventHeaderOverlap {
  position: relative;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 5px rgba(122, 122, 122, 0.5));
}

.eventHeaderOverlap2:hover{
  filter: drop-shadow(0px 0px 10px rgba(122, 122, 122, 0.6)) !important;
}

.eventTitle {
  max-width: 45%;
  color: white;
  background-color: rgba(var(--bg-color-rgb),0.7);
  border-radius: 1vw;
  padding: 0.5vw;
  position: absolute;
  top: 1vw;
  left: 0.8vw;
  max-height: 180px;
}

.eventName {
  margin-top: 0.2vw;
  margin-bottom: 0.2vw;
}

.eventDate {
  font-weight: bold;
  margin-bottom: 0.5vw;
  color: rgb(226, 226, 226);
}

.eventDescWrap {
  height: 4em;
}

.eventDesc {
  text-align: left;
  max-height: 5em;
  overflow-y: auto;
}

.eventDescMobile {
  text-align: left;
}

.boxShadow {
  border-radius: 1vw;
  background-color: #202020;
  width: 845px;
  border: 2px solid rgb(91, 23, 124);
}

.eventSection, .eventSection2 {
  background-color: rgb(184, 184, 184, 0.03);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 1vw !important;
  padding-top: 1vw !important;
  margin: 0.5vw !important;
}

.eventSection2 {
  max-height: 130px;
}

.eventSegment {
  padding: 0 !important;
}

.eventSectionPadded {
  padding: 1em !important;
}

.eventSectionH {
  margin-bottom: 1vw !important;
  font-size: large;
}

.eventSectionM {
  display: grid;
  text-align: center;
  max-width: 120px;
  margin: 1em 0.2em 1em 0.2em;
  padding: 1em 0 1em 0 !important;
}

.inline2 {
  display: inline-flex;
  align-items: center;
}

.leaderboardSelect {
  text-align: center;
  margin-bottom: 2em;
}

.inlineTrait {
  display:inline-block;
  vertical-align: middle;
  margin: 1vw;
  line-height: 28px;
  font-size: larger;
}

.inlineImg {
  display: inline !important;
  width: 28px;
  margin-right: 5px;
}

.blueLink {
  color:#7abaff;
}

.blueLinkColor {
  color:#7abaff;
}

.blueLink:hover {
  cursor: pointer;
  color: white;
}

.whiteLink {
  color:#e2e2e2;
}

.whiteLink:hover {
  cursor: pointer;
  color: white;
}

.eventTraits {
  display: grid !important;
}

@font-face {
  font-family: 'TNG_Title';
  src: local('TNG_Title'), url(./assets/fonts/TNG_Title.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.tng {
  font-family: TNG_Title;
}

.rowCN {
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  background-size: cover !important;
}

.imgBg {
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

.feature-image-col {
  width: 400px !important;
  min-width: 400px !important;
  min-height: 750px !important;
}

.feature-note-col {
  width: 700px !important;
}

.feature-note-text {
  font-weight: 100;
  text-align: left;
  font-size: larger;
  color: rgb(216, 216, 216);
  font-display: swap;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.8em;
  margin-left: -0.8em;
  border-radius: 1em;
}

.feature-stats {
  display: grid;
  margin-top: 2em;
  font-size:medium;
  text-align: left;
  vertical-align: middle;
}

.feature-traits {
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}

.tier {
  text-align: center;
  position: relative;
  height: 100%;
  padding: 0px !important;
}

.tierInner, .captainRank {
  display: grid;
  width: 100%;
  height: 63%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.tierInner, .largeFont {
  font-size: x-large;
}

.fNote {
  font-size: medium;
}

.mmenu {
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-top: 2px solid transparent !important;
}

.feature-coll {
  margin-bottom: 0.5em;
}

.feature-icon, .feature-icont {
  width: 28px !important;
}

.feature-icont {
  margin-bottom: 5px !important;
  height: 20px !important;
}

.feature-score, .feature-score-mobile {
  width: 95px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-left: 0px !important;
}

.feature-score-mobile {
  width: 80px;
  margin-left: 21px !important;
}

.feature-score:hover, .feature-tier:hover {
  border: 1px solid white;
  border-left: white;
  border-right: white;
  padding-left: 1px;
  padding-right: 1px;
}

.feature-score:hover {
  padding-left: 1px;
  padding-right: 1px;
}

.feature-tier:hover {
  padding-right: 11px;
  padding-left: 11px;
}

.feature-vidbtn {
  margin-top: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
}

.playerMobile {
  width: auto !important;
  height: 260px !important;
}

.playerMobile video {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.feature-tier, .feature-tier-mobile {
  background-color: var(--bg-color) !important;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0.28rem;
  border: 1px solid #3d3d3d;
  cursor: pointer;
  margin-right: 5px !important;
  margin-left: 15px !important;
  width: 85px;
}

.feature-tier-mobile {
  width: 80px;
}

.miniLD {
  white-space: nowrap !important;
}

.lockSlider:hover {
  cursor: pointer;
}

.rarityfilter, .rarityfilterSlider {
  margin: 10px;
  margin-top: 15px;
  color:#9b9b9b;
}

.rfmobile {
  padding-top: 5px;
  overflow-x: auto;
  white-space: nowrap;
  height: 2.8em;
}

.rarityfilterSlider {
  margin: 10px;
  margin-top: 0px;
  color:white;
  text-align: center;
}

.sliderLabel {
  display: grid;
  width: 70px;
  text-align: right;
}

.sliderInput {
  color: white !important;
  width: 40px;
}

.sliderButtons {
  display: block;
  text-align: center;
}

.ui.labeled.input>.label:not(.corner) {
  padding-top: .2em;
  padding-bottom: .2em;
}

.tierFilter {
  padding: 5px;
  margin: 5px;
  white-space: nowrap;
}

.tierFilter:hover {
  cursor: pointer;
}

.tierFilterSelected {
  background-color: #2c2c2c;
  border-radius: 0.28rem;
  border: 1px solid #3d3d3d;
  margin: 4px !important;
  color: white;
}

.infoIcon {
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -4px !important;
}

.fleetProfileLayout {
  display: grid;
  padding-left: 0.5em !important;
  margin-bottom: 0.5em;
  border-width: 2px !important;
  grid-template-columns: 100px max-content;
  grid-template-areas: "a b";
}

.capProfile, .capProfileM {
  display: grid;
  padding-left: 0.5em !important;
  border-width: 2px !important;
  grid-template-columns: 140px auto 100px;
  grid-template-areas:
    "a b d"
    "a c d"
    "a e d"
}

.capProfileM {
  padding-left: 0px !important;
  grid-template-columns: 80px 250px 100px;
  grid-template-areas:
    "a b d"
    "a c d"
    "e e e"
}

.capProfileAvatar, .capProfileAvatarM {
  grid-area: a;
  margin: 0.5em;
  margin-top: 0em;
  max-width: 125px !important;
}

.capProfileAvatarM {
  max-width: 100px !important;
  margin: 0em;
}

.capProfileName, .capProfileNameM {
  grid-area: b;
  font-size: x-large;
  align-self: center;
  min-width: 300px;
}

.capProfileNameM {
  font-size: large;
}

.capProfileFleet, .capProfileFleetM {
  grid-area: c;
  font-size: large;
  margin-top: 2px !important;
}

.capProfileFleetM {
  font-size: larger;
}

.capProfileAwards, .capProfileAwardsM {
  grid-area: d;
  margin-left: 1.5em;
  margin-top: 0.5em;
}

.capProfileAwardsM {
  margin: 0em;
  margin-top: -0.8em;
}

.capCommendations, .capCommendationsM {
  grid-area: e;
  margin: 0px !important;
  padding: 0px !important;
}

.capCommendationsM {
  max-width: 390px !important;
  padding-bottom: 1em !important;
}

.ribbonMini {
  width: 28px !important;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid #888888;
}

.ribbon, .ribbonM {
  padding: 0px !important;
  margin: 2px !important;
  margin-left: 4px !important;
  min-width: 50px !important;
  width: 50px !important;
  max-width: 50px !important;
}

.ribbonM {
  padding: 3px !important;
}

.ribbon img, .ribbonM img {
  border: 1px solid #888888;
}

.ribbonLarge {
  grid-area: b;
  box-shadow: 0 0 10px 1px #fff64947;
}

.commAward, .commAwardM {
  display: grid;
  grid-template-areas:
    "a a"
    "b c";
  grid-template-columns: 160px auto;
  row-gap: 0.5em;
  column-gap: 1em;
  font-size: large;
  margin-top: 0.5em;
  margin-bottom: 1em;
  padding: 1em;
  padding-top: 0.5em;
  border-left: 5px solid #646464;
}

.commAwardM {
  padding: 0.5em;
  grid-template-areas:
    "b c"
    "a c";
  border-left: 0px;
  margin-top: 0em;
}

.commText, .commTextM {
  display: inline;
  grid-area: a;
}

.commTextM {
  font-size: smaller;
}

.commAwardPlayers {
  grid-area: c;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  column-gap: 0.5em;
}

.commAwardPlayersM {
  grid-area: c;
  display: grid;
  grid-template-rows: max-content max-content max-content;
}

.commAwardPlayer {
  display: grid;
  grid-template-areas:
    "a b"
    "a c";
  grid-template-columns: 40px auto;
  column-gap: 0.2em;
  font-size: small;
}

.commAwardPlayerAvatar {
  grid-area: a;
  max-width: 40px;
}

.commAwardPlayerName {
  grid-area: b;
  font-weight: bold;
  padding-top: 0.2em;
}

.commAwardPlayerFleet {
  grid-area: c;
  font-size: smaller;
  padding-bottom: 0.2em;
}

.r2 {
  margin-left: 8px;
  display: inline;
}

.captainLink {
  color: white !important;
}

.captainLink:hover{
  color: 7abaff !important;
  cursor: pointer;
}

.shipAbs, .shipAbs > div {
  gap: 0.5em;
  margin: 0px !important;
  padding: 0px !important;
  margin-bottom: 2px !important;
}

.shipAction {
  display: grid;
  padding-left: 0.5em !important;
  border-width: 2px !important;
  width: 356px;
  gap: 0.5em;
  grid-template-areas:
    "a b"
    "c c"
    "s s"
    "d d";
}

.shipA1 {
  grid-area: a;
  margin-top: 0.1em;
}

.shipA2 {
  grid-area: b;
  margin-left: auto;
  margin-top: -1px;
  margin-right: -4px;
}

.shipA3 {
  grid-area: c;
  
}

.shipA4 {
  grid-area: s;
  display: grid;
  grid-template-columns: min-content 16px auto;
  column-gap: 2px;
  vertical-align: middle;
  align-items: center;
}

.shipA5 {
  grid-area: d;
}

.statsPanels {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 400px;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

.statsPanelsm {
  display: grid;
  min-width: 400px;
  min-height: 200px;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  grid-template-columns: 100%;
  grid-template-rows: 400px 400px;
}

.statsPanels2, .statsPanels2m {
  display: grid;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  grid-template-columns: 100%;
  grid-template-rows: 400px;
}

.statsPanels2m {
  grid-template-rows: 300px;
}

/* HIDE UNFILLFED ADS */
/* ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
} */

.adlabel {
  color: #646464;
  font-size: 0.75em;
  margin-top: 1em;
}

.adslot {
  min-width: 300px;
  margin-top: 0.5em;
  display: grid;
  background-color: #22222200;
  text-align: center;
  justify-content: center;
}

.ui.toggle.checkbox input:focus:checked~label {
  color: rgb(255, 255, 255)!important;
}

.ui.toggle.checkbox input:checked~label {
  color: rgb(255, 255, 255)!important;
}

.ui.toggle.checkbox input~label {
  color: rgb(255, 255, 255)!important;
}

.weightSlider {
  background-color:rgb(30,30,30) !important;
  border-radius: 5px !important;
  margin: 0.5em !important;
  padding: 0em !important;
}

.rarityfilter-0, .rarityfilter-1, .rarityfilter-2, .rarityfilter-3, .rarityfilter-4, .rarityfilter-5 {
  padding: 5px;
  margin: 5px;
}

.rarityfilter-4 {
  color: rgba(170, 45, 235, 1);
}

.rarityfilter-5 {
  color: rgba(253, 210, 106, 1);
}

.rarityselected {
  background-color: #2c2c2c;
  border-radius: 0.28rem;
  border: 1px solid #3d3d3d;
  margin: 4px !important
}

.rarityfilter-0:hover, .rarityfilter-1:hover, .rarityfilter-2:hover,.rarityfilter-3:hover, .rarityfilter-4:hover, .rarityfilter-5:hover {
  cursor: pointer;
}

.event-imgholder {
  height: 200px !important;
}

.event-imgholder-m {
  height: 100px !important;
}

.strikenout {
  text-decoration: line-through;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.twitchLogo {
  max-height: 20px;
}

.blob {
	background: rgb(190,0,0,1);
	border-radius: 50%;
	height: 10px;
	width: 10px;
  margin-left: 5px;

	box-shadow: 0 0 0 0 rgb(255, 0, 0);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}

.searchBar {
  margin-left: auto;
  margin-right: 0px;
  margin-top: 8px;
}

.searchBar .ui.input :focus {
  min-width: 280px;
}

.search .results {
  overflow-y: auto;
  max-height: 315px;
  overscroll-behavior: contain;
}

.result {
  padding: 2px !important;
  border: none !important;
  min-height: 44px !important;
}

.result:hover {
  background-color: rgb(41, 41, 41) !important;
}

.results {
  min-width: 280px;
}

.searchResult {
  display: grid;
  grid-template-columns: 40px 240px;
  vertical-align: middle;
  align-items: center;
}

.searchResult img {
  max-height: 40px;
  min-height: 40px;
  vertical-align: middle;
  align-items: center;
}

.searchResult span {
  font-size: larger;
  font-weight: bold;
  margin-left: 0.5em;
}

.credits {
  text-align: center;
  font-size: large;
}

.credits > p span {
  display: block;
}

.sbBoost {
  max-width: 50px;
  height: 22px;
  display: grid;
  grid-template-columns: 28px 22px;
  vertical-align: middle;
  align-items: center;
  align-content: center;
  text-align: center;
}

.sbBoost span {
  font-weight: bold;
  font-size: medium !important;
}

.sbBoost img {
  -webkit-filter: invert(1);
  filter: invert(1);
  margin-left: 1px;
  margin-right: 1px;
}

.sbAbility {
  display: grid;
  grid-template-columns: 30px auto;
  vertical-align: middle;
  align-items: center;
}

.sbAbility img {
  max-width: 30px;
  max-height: 30px;
}

.sbCharge0, .sbCharge1, .sbCharge2 {
  margin-right: 0.5em;
  border-width: 2px;
  border: 1px solid red;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.sbCharge0 {
  border-color: rgb(208,68,68);
}

.sbCharge1 {
  border-color: rgb(126,177,232);
}

.sbCharge2 {
  border-color: rgb(236,165,11);
}

.filter0 {
  filter: invert(55%) sepia(13%) saturate(7478%) hue-rotate(336deg) brightness(90%) contrast(78%);
}

.filter1 {
  filter: invert(35%) sepia(47%) saturate(360%) hue-rotate(171deg) brightness(93%) contrast(96%);
}

.filter2 {
  filter: invert(35%) sepia(54%) saturate(1805%) hue-rotate(8deg) brightness(107%) contrast(91%);
}

.inlinetext {
  display: inline-block !important;
  overflow: hidden;
  white-space: nowrap;
}

.inline {
  display: inline;
}

.mono {
  font-family: monospace;
}

.er-trait {
  margin-bottom: 5px;
}

.er-traitimg {
  margin-right: 4px !important;
}

.alignleft {
	float: left;
}
.alignright {
	float: right;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.gridDisplay {
  display: grid;
}

.notice {
  background-color: rgb(26, 26, 26);
  padding: 0.5em;
  position: fixed;
  z-index: 800;
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px !important;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.notice-inner {
  display: grid;
  justify-content: center;
  align-items: center;
}

.livestream {
  display: inline-flex;
}

.liveevent {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: small;
}

.leaderboardDate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  color: silver;
}

.livetext {
  margin-left:0.2em;
  margin-right:0.2em;
  color: rgb(207, 207, 207);
}

.audioPlayer, .audioPlayerM {
  display: block;
  border-radius: 30px;
  margin:0 auto;
  min-width: 350px;
  margin-bottom: 1em;
  border: 1px solid rgb(88, 88, 88);
  justify-content: center;
  height: 30px !important;
  width: 100% !important;
  max-width: 450px !important;
}

.audioPlayerM {
  border: none;
  max-width: 350px !important;
  margin-top: 1em;
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footerad[data-ad-status="unfilled"] {
  display: none !important;
}

.footer {
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 300px !important;
}

.footerLink {
  margin: 1em;
  color: white;
}

.footer-discordlink {
  color:white;
}

.footer-img {
  vertical-align: middle;
}

.footerLogo {
  position: absolute;
  height: 4em;
  opacity: 0.8;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);  
}

.centerdiv {
  align-items: center;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5em;
}

.kofi {
  border:0px;
  height:36px
}

.statBg {
  background-color: rgb(30,30,30,1) !important
}

.leaderboard-other {
  margin-top: 50px;
}

.crewStats { 
  margin-top: 2em;
}

.mobile-bases {
  text-align: center;
  table-layout: fixed;
  width: 100%;
  max-width: 350px;
}

.mobile-bases tbody tr {
  border-collapse: collapse;
}

.left {
  text-align: left;
}

.mobile-leaderboard {
  table-layout: fixed;
  width: 100%;
  max-width: 1000px;
}

.mobile-leaderboard tbody tr td {
  margin-top: 10px !important;
}

.mobile-leaderboard thead th:nth-child(1) {
  padding: 10px;
  width: 85%;
}

.mobile-leaderboard thead th:nth-child(2) {
  width: 15%;
  max-width: 30px;
}

.mobile-leaderboard tbody tr {
  padding-top: 10px !important;
}

.ui.styled.accordion .active.title {
  background: 0 0;
  color: #ffffff;
}

.appLogo {
  height: 3em;
  min-height: 3em;
  max-height: 3em;
  opacity: 0.8;
  margin: 5px;
  margin-right: 20px;
}

.appLogo:hover{
  cursor: pointer;
}

.experimental-skills {
  font-size: x-large;
  vertical-align: middle;
  white-space: nowrap;
}

.experimental-skills::after{
  content: "\a";
  white-space: pre;
}

.stats-label {
  margin-left: 0.2em !important;
  margin-top: 0.15em !important;
  margin-bottom: 0xp !important;
  vertical-align: text-bottom !important;
}

.feature-avatar-stats-mobile {
  justify-content: center;
  display: grid;
  align-items: center;
  text-align: center;
  vertical-align: bottom;
  grid-template-columns: min(200px,60%) auto;
}

.experimental-skills-mobile{
  vertical-align: middle;
  display: grid;
  grid-template-columns: 28% max-content;
  font-size: x-large;
}

.experimental-skill-tier{
  grid-column: 1/-1;
  font-size: x-large;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
}

.experimental-skill-tier span {
  border: 1px solid rgba(128, 128, 128, 0.432);
  border-radius: 0.3em;
  padding: 0.15em;
  padding-left: 0.3em;
  padding-right: 0.3em;
  color: silver;
}

.experimental-skill-mobile{
  vertical-align: middle;
  display: inline-flex;
  text-align: left;
  margin-top: 10px;
  white-space: nowrap;
}

.experimental-skill-prof-mobile {
  font-size: small;
  margin-left: 6px;
}

.readMore {
  color: rgb(184, 184, 184)
}

.readMore:hover {
  cursor: pointer;
}

.boostSwitch, .boostSwitch-mobile {
  color: rgba(255, 255, 255, 0.5);
  font-size: small;
}

.boostSwitch:hover {
  cursor: pointer;
}

.boostSwitch-mobile {
 display: inline;
 grid-column-start: span 2;
 text-align: center;
 align-content: center;
}

.feature-avatar-mobile {
  max-width: 100%;
  text-align: center;
  height: 200px;
  width: 200px;
}

.feature-note-text-mobile {
  font-weight: 100;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: larger;
  text-align: left;
}

.feature-stats-mobile {
  display: inline-block;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size:medium;
  text-align: left;
}

.amznad {
  max-width: 125px !important;
}

.ad {
  margin-top: 2px;
  margin-bottom: 0.5em;
  min-width: 300px !important;
}

.adfixed {
  margin-top: 2px;
}

.adwide {
  width: 970px;
}

.adtext {
  margin-top: 2px;
  min-width: 300px !important;
}

.experimental-skill {
  vertical-align: middle;
  margin-right: 5px;
}

.experimental-skillicon-container {
  display: inline;
  width: 50px;
  vertical-align: middle;
  text-align: center;
  margin-top: 5px;
}

.experimental-skillicon-mobile {
  height: 32px;
}

.qname {
  display: inline-block;
  font-weight: bold;
  font-size: smaller;
  line-height: 16px;
}

.qskilldata {
  display: grid;
  font-size: small;
  vertical-align: middle;
}

.qskill {
  line-height: 16px;
}
.qskillicon {
  height: 16px;
  display: inline-block;
  vertical-align: middle;
}

.qskilliconcontainer {
  width: 25px !important;
  display: inline-block;
  text-align: center;
}

.qoptimal {
  font-size: smaller;
  border: 1px solid grey;
  border-radius: 10px;
  margin: 2px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 1px;
  color: silver;
}

.experimental-skillicon {
  height: 28px;
  vertical-align: middle;
}

.experimental-skillcore {
  font-weight: 700;
  vertical-align: middle;
}

.experimental-skillcore::after {
  content: " ";
  white-space: pre;
}

.experimental-skillprof {
  font-size: small;
  vertical-align: text-bottom;
}

.feature-mobile {
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.event-shadow
{
    display:block;
    position:relative;
}

.event-shadow:before
{
    display:block;
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    -moz-box-shadow:inset 0px 0px 10px 20px var(--bg-color);
    -webkit-box-shadow:inset 0px 0px 10px 20px var(--bg-color);
    box-shadow:inset 0px 0px 10px 20px var(--bg-color);
}

.event-image {
    text-align: center;
    align-items: center;
}

.event-innerimage, .event-innerimage-m {
    width: 100%;
    height: 100%;
    max-height: 200px;
}

.event-innerimage-m {
  max-height: 100px;
}

.eventInnerDesc {
  height: 6em;
  overflow-y: auto;
}

.eventHeaderNew {
  text-align: center;
}

.eventNameNew {
  line-height: 4vh !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em;
  font-size: xxx-large;
}

.eventDateNew {
  font-size: medium;
  font-weight: bold;
  margin-bottom: 0.5vh;
  color: rgb(226, 226, 226);
}

.customBG .eventInnerDescNew {
  font-size: medium;
  max-width: 80%;
  margin: 0 auto !important;
  border: 1px solid rgba(100,100,100,0.1);
  background-color: rgb(0, 0, 0, 0.4);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 1vw !important;
  padding-top: 1vw !important;
}

.eventInnerDescNew {
  font-size: medium;
  max-width: 80%;
  margin: 0 auto !important;
  background-color: rgb(184, 184, 184, 0.03);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 1vw !important;
  padding-top: 1vw !important;
  margin: 0.5vw !important;
}

.eventDataSection {
  margin-bottom: 2em;
}